import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  PageWrapper,
  Title,
  Description,
  LettersWrapper,
  Image,
  SlideTransitionWrapper,
  ArrowsWrapper,
  LeftArrowWrapper,
  RightArrowWrapper,
} from "./style";
import { PageChange } from "../../../../../common/Scroll";
import { FluidImageType } from "../../../../../../utils/types";
import { PageTitle } from "../../../../../common/PageTitle";
import { fixConjunctions } from "../../../../../../utils/dropTheWord";
import ArrowLeft from "-!svg-react-loader!../../../../../../assets/images/arrowLeft.svg";
import ArrowRight from "-!svg-react-loader!../../../../../../assets/images/arrowRight.svg";

interface LettersMobilesProps {
  pageChange: PageChange;
}

const LettersMobile: React.FC<LettersMobilesProps> = ({ pageChange }) => {
  const { t } = useTranslation("main");
  const data = useStaticQuery(lettersQuery);
  const description = t(`eko_description`);
  const description_points = t(`eko_description_points_mobile`);
  const nOfSlides = 5;

  const [slide, setSlide] = useState<number>(
    pageChange === "previous" ? nOfSlides : 0
  );
  const [slideTransition, setSlideTransition] = useState<PageChange>();

  const renderEkoLetters = () =>
    data.allFile.nodes.map((img: FluidImageType, index: number) => (
      <Image key={index} fluid={img.childImageSharp.fluid} />
    ));

  const handlePrevious = () => {
    if (slide > 0) {
      setSlideTransition("previous");
      setTimeout(() => {
        const newSlide = slide - 1;
        setSlideTransition(undefined);
        setSlide(newSlide);
      }, 300);
    }
  };

  const handleNext = () => {
    if (slide < nOfSlides) {
      setSlideTransition("next");
      setTimeout(() => {
        const newSlide = slide + 1;
        setSlideTransition(undefined);
        setSlide(newSlide);
      }, 300);
    }
  };

  return (
    <>
      <PageTitle subtitle={t(`eko_title`)} />
      <PageWrapper animation={pageChange}>
        <Title>
          <div>{t(`eko_title1`)}</div>
          <div>{t(`eko_title2`)}</div>
        </Title>
        <SlideTransitionWrapper slideChange={slideTransition}>
          {slide === 0 ? (
            <Description>{description}</Description>
          ) : slide === 1 ? (
            <Description
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(description_points[0]),
              }}
            />
          ) : slide === 2 ? (
            <Description
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(description_points[1]),
              }}
            />
          ) : slide === 3 ? (
            <Description
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(description_points[2]),
              }}
            />
          ) : slide === 4 ? (
            <Description
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(description_points[3]),
              }}
            />
          ) : (
            <Description
              dangerouslySetInnerHTML={{
                __html: fixConjunctions(description_points[4]),
              }}
            />
          )}
        </SlideTransitionWrapper>
        <LettersWrapper>{renderEkoLetters()}</LettersWrapper>
        <ArrowsWrapper>
          <LeftArrowWrapper
            slideChange={slide === 1 ? slideTransition : undefined}
          >
            {slide > 0 && <ArrowLeft onClick={handlePrevious} />}
          </LeftArrowWrapper>
          <RightArrowWrapper
            slideChange={slide === nOfSlides - 1 ? slideTransition : undefined}
          >
            {slide < nOfSlides && <ArrowRight onClick={handleNext} />}
          </RightArrowWrapper>
        </ArrowsWrapper>
      </PageWrapper>
    </>
  );
};

const lettersQuery = graphql`
  {
    allFile(
      filter: { relativeDirectory: { eq: "okeLetters" } }
      sort: { order: DESC, fields: [name] }
    ) {
      nodes {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;

export default LettersMobile;
