import styled, { css } from "styled-components";
import Img, { FluidObject } from "gatsby-image";

import { device } from "../../../../../../utils/breakpoints";
import {
  fadeIn,
  fadeOut,
  slideToLeft,
  slideToRight,
} from "../../../../../../utils/animations";
import { H4, TEXT_BODY_2 } from "../../../../../../assets/styles/typography";
import { PageChange } from "../../../../../common/Scroll";

interface PageProps {
  animation?: string;
}

export const PageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  animation: ${(props: PageProps) =>
    props.animation
      ? css`
          ${fadeOut} 300ms linear
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
  padding: 160px 24px 0;
`;

export const Title = styled.h2`
  ${H4}
  width: 100%;
  margin: 0;
  color: #151515;
  white-space: pre-line;

  div:first-child {
    color: #013cff;
  }
`;

export const Description = styled.div`
  ${TEXT_BODY_2}
  margin-top: 24px;
  display: block;
  color: #151515;
  white-space: pre-line;
`;

export const LettersWrapper = styled.div`
  width: 100vw;
  white-space: nowrap;
  letter-spacing: 0px;
  word-spacing: 0px;
  margin: 16px -24px;
`;

export const Image = styled(Img)<{
  fluid: FluidObject | FluidObject[];
}>`
  display: inline-block;
  width: 20%;
  margin: 2vh 10%;

  img {
    object-fit: contain !important;
  }

  &:nth-of-type(3n - 1) {
    margin: 2vh 0;
  }

  &:nth-of-type(2n) {
    margin: (100% - 72% - 48px)/2 0;
    margin-bottom: 6vh;
  }

  @media ${device.mobileM} {
    width: 24%;
    margin: 2vh 7%;
  } ;
`;
interface SlideTransitionProps {
  slideChange: PageChange;
}

export const SlideTransitionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0;
  padding: 0;
  animation: ${(props: SlideTransitionProps) =>
    props.slideChange
      ? css`
          ${props.slideChange === "next"
            ? slideToLeft
            : slideToRight} 300ms ease-in
        `
      : css`
          ${fadeIn} 300ms linear
        `};
  animation-fill-mode: forwards;
`;

export const ArrowsWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 24px 0;
  display: grid;
  grid-template-columns: repeat(50%, 2);

  svg > rect {
    fill: #013cff;
  }
`;

export const LeftArrowWrapper = styled.div`
  grid-column: 1;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "previous" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;

export const RightArrowWrapper = styled.div`
  grid-column: 2;
  margin-left: auto;

  svg {
    animation: ${fadeIn} 300ms linear;
    ${(props: SlideTransitionProps) =>
      props.slideChange === "next" &&
      css`
        animation: ${fadeOut} 300ms linear;
      `};
    animation-fill-mode: forwards;
  }
`;
